var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  Drupal.behaviors.skuCategoriesV1 = {
    attach: function (context, settings) {
      var isMobile = !!settings && !!settings.is_mobile;

      if (isMobile) {
        return null;
      }
      var $wrap = $('.js-product__sku-categories', context);

      if ($wrap.length < 1) {
        return null;
      }
      var prodId = $wrap.data('product-id');

      if (!prodId) {
        return null;
      }
      var prodData = prodcat.data.getProduct(prodId);

      if (!prodData) {
        return null;
      }
      var collectionSkus = _.filter(prodData.skus, function (sku) {
        return !!sku['COLLECTION_NAME'];
      });

      if (collectionSkus.length < 1) {
        // There is only one tab in this case, so give it the active tab class, and exit
        $('.js-sku-categories-tab').first().addClass('resp-tab-active');

        return null;
      }
      var collections = _.groupBy(collectionSkus, 'COLLECTION_NAME');
      var $tabsList = $('.js-sku-categories-tabs');
      var $contents = $('.js-sku-categories-content');

      _.each(collections, function (collection, key) {
        var tabHtml = "<li class='js-sku-categories-tab product__sku-categories-nav-item'>";

        tabHtml += key;
        tabHtml += '</li>';
        $tabsList.append(tabHtml);
        var contentHtml = '<div>';
        var swatchListHtml = site.template.get({
          name: 'swatch_list_v1',
          data: {
            PRODUCT_ID: prodId,
            skus: collection
          }
        });

        contentHtml += swatchListHtml;
        contentHtml += '</div>';
        $contents.append(contentHtml);
      });

      $wrap.easyResponsiveTabs();
    }
  };
})(jQuery);
